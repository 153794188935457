@import "vendor/primer/layout";
@import "vendor/primer/primer";

.avatar-scale {
    width: 128px;
    height: 128px;

    @include breakpoint(sm) {
        width: 192px;
        height: 192px;
    }
}
